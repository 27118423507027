<template>
  <v-form v-model="formValid">
    <v-subheader class="font-italic font-weight-light py-1">Generale</v-subheader>
    <v-row no-gutters class="py-1">
      <v-col>
        <v-text-field
          v-model="name"
          :rules="[max255CharRule, presenceRule]"
          dense
          filled
          label="Nome"
          hide-details="auto"
          @input="updateObject('name', $event)"
        ></v-text-field>
        <div class="mt-2">
        <v-text-field
        v-model="cashFund"
          :rules="[presenceRule, greaterThan0]"
          dense
          filled
          label="Fondo cassa"
          hide-details="auto"
          prefix="€"
          type="number"
          @input="updateObject('cashFund', $event)"
        ></v-text-field>
        </div>
        <div class="mt-2">
        <v-text-field
          :rules="[presenceRule, isIP]"
          v-model="printer"
          filled
          dense
          hide-details="auto"
          label="Indirizzo stampante RT"
          @input="updateObject('printer', $event)"
        ></v-text-field>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from 'vue'
import cashDeskForm from '@/services/cashDesks/cashDesk.form.js'

export default {
  name: "GeneralFormTab",
  components: {},
  data: function() {
    return {
      name: undefined,
      description: undefined,
      loadingCashDesk: false,
      formValid: false,
      cashFund: undefined,
      printer: undefined,
      max255CharRule: (v) => {
        if(v) {
          return v.length <= 255 || 'Al massimo 255 caratteri'
        } else {
          return true
        }
      },
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
      greaterThan0: (v) => {
        return (!isNaN(v) && v >= 0 ) || "Importo non valido";
      },
      isIP: (v) => {
        return (!!v && v.match(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/)) || 'Indirizzo IP non valido'
      }
    };
  },
  props: {
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      }
    },
    cash: {
      type: Function
    }
  },
  mounted: function() {
    this.handleObjectChanges(cashDeskForm.cashDesk)

    var self = this
    cashDeskForm.on('update', function(data) {
      self.handleObjectChanges(data.cashDesk)
    })
  },
  methods: {
    fields() {
      return ['name', 'cashFund', 'printer']
    },
    handleObjectChanges(cashDesk) {
      const fields = this.fields()
      const newValKeys = Object.keys(cashDesk)

      for(let i = 0; i < fields.length; i++) {
        const field = fields[i]
        if(newValKeys.includes(field) && cashDesk[field] != this[field]) {
          this[field] = cashDesk[field]
        }
      }
    },
    updateObject(key, value) {
      cashDeskForm.updateField(key, value);
    },
  },
  watch: {
    formValid(newVal) {
      this.$emit('update:valid', newVal)
      if(this.bus) {
        this.bus.$emit('update:valid', newVal)
      }
      cashDeskForm.setValid(newVal)
    }
  }
};
</script>